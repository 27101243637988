<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <div class="pad-container flx-row ali-c">
        <el-input placeholder="请输入活动名称" class="search-input" v-model="searchObj.goodsName"></el-input>
        <el-select placeholder="请选择活动状态" class="search-input ml-15" v-model="searchObj.goodsStatus">
          <el-option
            v-for="(item,index) in goodsStatusOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select placeholder="请选择活动形式" class="search-input ml-15" v-model="searchObj.goodsType">
          <el-option
            v-for="(item,index) in goodsTypeOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
        <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
      </div>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <el-button type="primary" class="mb-10" @click="handleAdd">
            <i class="el-icon-plus mr-10"></i>添加活动
          </el-button>
          <el-button type="primary" class="mb-10" @click="handleAddCopy">
            <i class="el-icon-plus mr-10"></i>复制活动模板
          </el-button>
          <el-tabs v-model="activePart" @tab-click="changeTab" v-if="!storeFlag">
            <el-tab-pane
              :label="item.storeName"
              :name="item.id+''"
              v-for="(item,index) in shopList"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
          <div>
            <!-- 表格 -->
            <el-table class="mt-24 member-table" :data="tableData">
              <el-table-column label="序号" width="100"> 
                <template slot-scope="scope">{{scope.$index+1+(pageNum-1)*pageSize}}</template>
              </el-table-column>
              <el-table-column label="活动名称" prop="activityName"></el-table-column>
              <el-table-column label="所属门店" prop="storeName"></el-table-column>
              <!-- 4-20new -->
              <el-table-column label="活动形式" prop="typeName"></el-table-column>
              <el-table-column label="活动时间" prop="activityTime"></el-table-column>
              <!-- 4-20new end -->
              <el-table-column label="创建时间" prop="createTime"></el-table-column>
              <el-table-column label="上架状态" prop="status">
                <template slot-scope="scope">
                  <div class="flx-row">
                    <el-switch
                     
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="0"
                      @change="toggle(scope.row)"
                    ></el-switch>
                    <span class="ml-5">{{scope.row.status?'上架':'下架'}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="240">
                <template slot-scope="scope">
                  <div class="flx-row ali-c">
                    <el-button type="text" @click="copy(scope.row.id)">
                      设为模板
                    </el-button>
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="handleEdit(scope.row.id)"
                      class="mr-10"
                    >
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link>
                    <el-link type="primary" :underline="false" @click="handleDel(scope.row)">
                      <i class="el-icon-delete"></i>
                      删除
                    </el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- <GoodsDialog ref="GoodsDialog" :tableHeader="tableHeader" :tabList="tabList" :hasTab="true"></GoodsDialog> -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import GoodsDialog from "@/components/GoodsDialog";
import { getStoreList } from "@/api/common";
import {
  getActivityList,
  upAndDownActivity,
  delActivty,
  getActivityDetailBack
} from "@/api/market/activity";

export default {
  name: "goods_list",
  components: {
    Pagination,
    GoodsDialog
  },
  data() {
    return {
      goodsUrl: "/goods-service/goods/info/list",
      id: 1,
      // 商品列表表头
      tableHeader: [
        {
          value: "商品条形码",
          key: "barCode"
        },
        {
          value: "活动名称",
          key: "activityName"
        },
        {
          value: "一级类目",
          key: "firstClassify"
        },
        {
          value: "二级类目",
          key: "secondClassify"
        },
        {
          value: "实价",
          key: "price"
        },
        {
          value: "库存",
          key: "stock"
        }
      ],
      activePart: "",
      // 顶部搜索栏数据
      searchObj: {
        barCode: "",
        goodsName: "",
        firstClassify: "",
        secondClassify: "",
        goodsStatus: "",
        goodsType:"",
        completeInfo: ""
      },
      // 一级分类下拉选项
      firstClassifyOptions: [
        { id: 0, name: "一级分类1" },
        { id: 1, name: "一级分类2" },
        { id: 2, name: "一级分类3" }
      ],
      // 二级分类下拉选项
      secondClassifyOptions: [
        { id: 0, name: "二级分类1" },
        { id: 1, name: "二级分类2" },
        { id: 2, name: "二级分类3" }
      ],
      // 商品状态下拉选项
      goodsStatusOptions: [
        { id: 0, name: "下架" },
        { id: 1, name: "上架" }
      ],
      // 是否完善信息下拉选项
      completeInfoOptions: [
        { id: 0, name: "是" },
        { id: 1, name: "否" }
      ],
      goodsTypeOptions:[
        { id: 0, name: "优惠限购" },
        { id: 1, name: "满减运费" }
      ],

      // 拼团表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      shopList: [],
      upFlag: true,
      tabList: [
        {
          label: "商超商品",
          name: "1",
          url: "/goods-service/goods/info/list",
          method: "post",
          key: "goodsId"
        },
        {
          label: "生鲜商品",
          name: "2",
          url: "http://www.tourask.com/mock/152/api/goodsList",
          method: "get",
          key: "id"
        }
      ],
      storeName: "",
      storeFlag: false,
      copyData:{
        activityAllowance:{
          activityPersonnel:0,
          allowanceType:0,
          freight:0,
          numberOffers:0,
          standardAmount:0,
        },
        activityName:'',
        activityRelations:[],
        activityType:'',
        endTime:'',
        picture:'',
        startTime:'',
        storeId:'',
        storeName:'',
      },
    };
  },
  mounted() {
    if (this.$store.getters.storeId) {
      this.activePart = this.$store.getters.storeId;
      this.storeFlag = true;
    }
    this.getStoreList(); //查门店列表
  },
  watch: {
    activePart(val) {
      // this.search();
    }
  },
  methods: {
    //获取门店列表
    getStoreList() {
      getStoreList()
        .then(res => {

          this.shopList = res.data.body.list;
          this.activePart = res.data.body.list.length && String(res.data.body.list[0].id) ;
          this.getGoodsList();
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // 添加
    handleAdd() {
      let index = this.shopList.findIndex(item => {
        return item.id == this.activePart;
      });
      this.$router.push({
        name: "Special_goods_new",
        query: {
          storeId: this.activePart,
          storeName: this.shopList[index].storeName
        }
      });
    },
    //   删除
    handleDel(row) {
      if (row.status == 1) {
        this.$message.warning("请先将活动下架之后再删除");
        return;
      }
      this.isConfirm(
        this,
        () => {
          delActivty({ id: row.id }).then(res => {
            this.getStoreList();
          });
        },
        "确定要删除吗？ 删除之后活动恢复原价售卖"
      );
    },
    // 查看商品
    handleShow() {
      this.$refs.GoodsDialog.showGoods = true;
      this.$refs.GoodsDialog.getGoods();
    },
    // 编辑
    handleEdit(id) {
         let index = this.shopList.findIndex(item => {
        return item.id == this.activePart;
      });
      this.$router.push({
        name: "Special_goods_new",
        query: {
          id,
          storeId: this.activePart,
          storeName: this.shopList[index].storeName
        }
      });
    },
    handleAddCopy(){
      if(this.copyData.activityName){
        let index = this.shopList.findIndex(item => {
        return item.id == this.activePart;
        });
        this.copyData.storeId = this.activePart
        this.copyData.storeName = this.shopList[index].storeName
        this.$router.push({
          name: "Special_goods_new",
          query: {
            copy:this.copyData,
            storeId: this.activePart,
            storeName: this.shopList[index].storeName
          }
        });
      }else{
        this.$message('您还未选择设置模板')
      }
    },
    // 上下架验证
    toggle(row) {
      let reg = /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/g;
      let res = row.activityTime.match(reg);

      const currTime = new Date().getTime()
      const startTime = new Date(res[0]).getTime()
      const endTime = new Date(res[1]).getTime()
      const params = {
        id: row.id,
        status: row.status
      };
      if(row.status===1&&currTime<startTime){ //上架
        this.$confirm('活动还未到达开始时间，是否现在上架？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          upAndDownActivity(params).then(res => {});
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消上架'
          });   
          row.status=0       
        });
        return ;
      }else if(row.status===0&&currTime<endTime){ //下架
        this.$confirm('活动还未到达结束时间，是否现在下架？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          upAndDownActivity(params).then(res => {});
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下架'
          });  
          row.status=1        
        });
        return ;
      }
      upAndDownActivity(params).then(res => {});
      
    },
    // 上下架请求
    toggleReq() {
      request({
        url,
        method: "put"
      }).then(res => {
        this.getGoodsList();
      });
    },
    // 切换tab
    changeTab(tab, event) {
      let id = tab.name;
      this.storeName = tab.storeName;
      this.pageNum = 1;
      this.pageSize = 10;
      this.getGoodsList();
    },
    //数据初始化
    getGoodsList() {
      let params = {
        storeId: this.activePart,
        status: this.searchObj.goodsStatus,
        activityName: this.searchObj.goodsName,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        activityType: this.searchObj.goodsType,
      };

      getActivityList(params).then(res => {
        let data = res.data.body;
        this.total = data.total;
        this.tableData = data.list;
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getGoodsList();
    },
    // 重置搜索关键词
    reset() {
      for (let key in this.searchObj) {
        this.searchObj[key] = "";
      }
      this.search();
    },
    // 页面列表 pageNum pageSize获取
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.getGoodsList();
    },
    // 获取当前选中行的数据，存入模板变量
    copy(id){
      getActivityDetailBack({id:id}).then(res=>{
        // this.copyData = JSON.parse(JSON.stringify(res.data.body)) //存储
        if(res.data.body.activityAllowance){
          this.copyData.activityAllowance.activityPersonnel = res.data.body.activityAllowance.activityPersonnel
          this.copyData.activityAllowance.allowanceType = res.data.body.activityAllowance.allowanceType
          this.copyData.activityAllowance.freight = res.data.body.activityAllowance.freight
          this.copyData.activityAllowance.numberOffers = res.data.body.activityAllowance.numberOffers
          this.copyData.activityAllowance.standardAmount = res.data.body.activityAllowance.standardAmount
        }
        this.copyData.activityName = res.data.body.activityName+'模板复制'
        if(res.data.body.activityRelations){
           this.copyData.activityRelations = res.data.body.activityRelations
        }
        this.copyData.activityType = res.data.body.activityType
        this.copyData.endTime = res.data.body.endTime
        this.copyData.picture = res.data.body.picture
        this.copyData.startTime = res.data.body.startTime
        this.$message({
          message:'您已将'+this.copyData.storeName+this.copyData.activityName+"活动设置为模板",
           type: 'success',
        }
        )
        // this.copyData.activityName = this.copyData.activityName
        // this.copyData.id = ''
        console.log(this.copyData)
      })
    }
   
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case 0:
          return "等待中";
        case 1:
          return "进行中";
        case 2:
          return "已结束";
        default:
          return "状态未知";
      }
    }
  },
  
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>
